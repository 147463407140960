export const links = [
    {
        id:1,
        text:'Projects',
        url:'#projects'
    },
    {
        id:2,
        text:'Contact',
        url:'#contact'
    },
    {
        id: 3,
        text: 'CV',
        url: '#cv'
    },
    /*{
        id: 4,
        text: 'Blog',
        url: 'blog'
    }*/
]