export const TechnologyList = [
    {
        id: 1,
        name: 'HTML',
        img : './img/html.webp',
    },
    {
        id: 2,
        name: 'CSS',
        img : './img/css.webp',
    },
    {
        id: 3,
        name: 'JavaScript',
        img : './img/js.png',
    },
    {
        id: 4,
        name: 'React',
        img : './img/react.png',
    },
]