export const ProjectList = [
    {
        id: 1,
        name: 'Portfolio',
        desc: 'Portfolio website made with React to showcase my projects and skills.',
        techstack: 'React',
        date: '2021',
        img : './projects/portfolio-phone.png',
        imgSmall : './projects/portfolio-phone-small.png',
        imgLaptop : './projects/portfolio-laptop.png',
        imgPC : './projects/portfolio-pc.png',
        repo_name: 'portfolio',
        live: 'https://koppanydavid.hu',
    },
    {
        id: 2,
        name: 'Instagram Clone',
        desc: 'React website developed to function like Instagram.',
        techstack: 'React',
        date: '2021',
        img : './projects/instagram-phone.png',
        imgSmall : './projects/instagram-phone-small.png',
        imgLaptop : './projects/instagram-laptop.png',
        imgPC : './projects/instagram-pc.png',
        repo_name: 'instagram-react-clone',
        live: 'https://instagram-react-clone-kdavid96.netlify.app/',
    },
    {
        id: 3,
        name: 'E-commerce',
        desc: 'E-commerce app made width React and Redux. Work in progress.',
        techstack: 'React/Redux',
        date: '2021',
        img : './projects/ecommerce-phone.png',
        imgSmall : './projects/ecommerce-phone-small.png',
        imgLaptop : './projects/portfolio-laptop.png',
        imgPC : './projects/portfolio-pc.png',
        repo_name: 'ecommerce-react-redux',
        live: 'https://kdavid-react-redux-ecommerce.netlify.app/',
    },
    {
        id: 4,
        name: 'Baranyi Épületvillamosság',
        desc: 'Website made with react, to help the company engage with potential customers. Work in progress',
        techstack: 'React',
        date: '2021',
        img : './projects/baranyi-phone.png',
        imgSmall : './projects/baranyi-phone-small.png',
        imgLaptop : './projects/baranyi-laptop.png',
        imgPC : './projects/baranyi-pc.png',
        repo_name: 'baranyi_epuletvillamossag',
        live: 'https://baranyiepuletvillamossag.netlify.app/',
    },
    /*{
        id: 5,
        name: 'Communication',
        desc: <div>Application for communication between doctors and clients. <br/>You can try it out with the following credentials:<br/>Username : admin@admin.hu <br/>Password : admin123</div>,
        techstack: 'Angular/Firebase',
        date: '2021',
        img : './projects/communication-phone.png',
        imgSmall : './projects/communication-phone-small.png',
        imgLaptop : './projects/portfolio-laptop.png',
        imgPC : './projects/portfolio-pc.png',
        repo_name: 'webkert-communication',
        live: 'https://webkert-4b250.web.app/',
    },*/
]
